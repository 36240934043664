

$(document).ready(function() {

    // NavBar auto-selection --------------------------------------
    var url = window.location;

    // Will only work if string in href matches with location
    $('ul.nav a[href="' + url + '"]').parent().addClass('active');

    // Will also work for relative and absolute hrefs
    $('ul.nav a').filter(function() {
        return this.href == url;
    }).parent().addClass('active');

    // Bootstrap Tooltips, Popovers
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    });

    $.ajaxSetup({
        type: "POST",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "json",
        error: function(e) {
            console.log(e.responseText);
        }
    });
});


